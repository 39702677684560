import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

function ManageSubUsers() {
  const [subUsers, setSubUsers] = useState([]);
  const [newUserName, setNewUserName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPhone, setNewUserPhone] = useState('');
  const [expirationHours, setExpirationHours] = useState(24);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const apiBaseUrl = process.env.REACT_APP_NODE_ENV === 'development'
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchSubUsers();
  }, []);

  const fetchSubUsers = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiBaseUrl}/users/subusers`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSubUsers(data.subUsers || []);
      } else {
        console.error('Failed to fetch subusers');
      }
    } catch (error) {
      console.error('Error fetching subusers:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiBaseUrl}/users/create-subuser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          username: newUserName,
          email: newUserEmail,
          phoneNumber: newUserPhone || null,
          expirationHours: parseInt(expirationHours),
        }),
      });
      const data = await response.json();
      if (response.ok) {
        let alertMessage = `Subuser created successfully!\n\nOne-Time Code: ${data.oneTimeCode}\nTemporary Password: ${data.tempPassword}\nExpires at: ${new Date(data.expiresAt).toLocaleString()}\n\nPlease securely share these credentials with the subuser.`;

        if (data.smsSent) {
          alertMessage += `\n\nAn SMS has been sent to the subuser's phone number.`;
        }

        alert(alertMessage);
        setNewUserName('');
        setNewUserEmail('');
        setExpirationHours(24);
        fetchSubUsers(); // Refresh the list
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error creating sub-user:', error);
      alert('An unexpected error occurred');
    }
  };

  const handleRegenerateCode = async (userId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiBaseUrl}/users/regenerate-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          subUserId: userId,
          expirationHours: expirationHours,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        alert(`New one-time code generated:\n\nOne-Time Code: ${data.oneTimeCode}\nExpires at: ${new Date(data.expiresAt).toLocaleString()}\n\nPlease securely share this new code with the subuser.`);
        fetchSubUsers(); // Refresh the list
      } else {
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error('Error regenerating code:', error);
      alert('An unexpected error occurred');
    }
  };
  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <ClipLoader color="#ffffff" size={50} />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold text-center text-white mb-6">Manage Sub-Users</h2>
        <form onSubmit={handleCreateUser} className="space-y-4 mb-8">
          <input
            type="text"
            value={newUserName}
            onChange={(e) => setNewUserName(e.target.value)}
            placeholder="Username"
            className="w-full p-3 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            required
          />
          <input
            type="email"
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
            placeholder="Email"
            className="w-full p-3 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            required
          />
          <input
            type="tel"
            value={newUserPhone}
            onChange={(e) => setNewUserPhone(e.target.value)}
            placeholder="Phone Number"
            className="w-full p-3 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <div className="space-y-1">
            <label htmlFor="expirationHours" className="block text-sm font-medium text-gray-300">
              Access Duration (hours)
            </label>
            <input
              id="expirationHours"
              type="number"
              value={expirationHours}
              onChange={(e) => setExpirationHours(e.target.value)}
              placeholder="e.g., 24 for 1 day"
              className="w-full p-3 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              required
              min="1"
            />
          </div>
          <button type="submit" className="w-full p-3 rounded bg-indigo-500 text-white font-bold hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500">
            Create Sub-User
          </button>
        </form>
        <h3 className="text-xl font-bold text-white mb-4">Existing Sub-Users</h3>
        <ul className="space-y-2 mb-4">
          {subUsers.map(user => (
            <li key={user._id} className="p-3 bg-gray-700 rounded text-white flex justify-between items-center">
              <div>
                <strong>{user.username}</strong> - {user.email}
                <br />
                <span className="text-sm text-gray-400">
                  Expires: {new Date(user.expiresAt).toLocaleString()}
                </span>
              </div>
              <button
                onClick={() => handleRegenerateCode(user._id)}
                className="ml-2 px-3 py-1 bg-indigo-500 text-white text-sm rounded hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Regenerate Code
              </button>
            </li>
          ))}
        </ul>
        <button
          onClick={() => navigate('/console/dashboard')}
          className="w-full p-3 rounded bg-gray-600 text-white font-bold hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
        >
          Back to Dashboard
        </button>
      </div>
    </div>
  );
}

export default ManageSubUsers;