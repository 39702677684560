import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate, useLocation, useNavigate, Link } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import PrivacyPolicy from './components/PrivacyPolicy';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import SignUp from './components/SignUp';
import Logout from './components/Logout';
import { isAuthenticated, logout } from './auth';
import ManageSubusers from './components/ManageSubUsers';
import LandingPage from './components/LandingPage';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import dcpayIos from './assets/dcpay-ios.png';


function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Initialize isLoggedIn based on the presence of token in localStorage
    return !!localStorage.getItem('token');
  });
  const [username, setUsername] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const canonicalUrl = `${process.env.REACT_APP_API_URL}${location.pathname}`;

   // Function to get SEO data based on the current route
   const getSeoData = (pathname) => {
    switch (pathname) {
      case '/':
        return {
          title: 'DC Tap to Pay - The Future of Contactless Payments',
          description: 'Transform your smartphone into a powerful payment terminal with DC Tap to Pay. Accept contactless payments, integrate with your CRM, and gain 360° customer insights. No extra hardware required.',
          keywords: 'DC Tap to Pay, contactless payments, mobile payments, tap to pay, iPhone payments, Android payments, CRM integration, payment solution',
          ogImage: dcpayIos
        };
        case '/privacy-policy':
          return {
            title: 'Privacy Policy | DC Tap to Pay',
            description: 'Learn about DC Tap to Pay\'s commitment to protecting your privacy and how we handle your data in our secure mobile payment solution.',
            keywords: 'privacy policy, data protection, secure payments, DC Tap to Pay, mobile payment privacy',
          };
        case '/signup':
          return {
            title: 'Sign Up for DC Tap to Pay | Start Accepting Mobile Payments',
            description: 'Create your DC Tap to Pay account and transform your smartphone into a powerful payment terminal. Start accepting contactless payments today.',
            keywords: 'sign up, register, DC Tap to Pay account, mobile payments, contactless payments',
          };
        case '/console':
          return {
            title: 'Login to DC Tap to Pay | Manage Your Payment Terminal',
            description: 'Access your DC Tap to Pay account to manage your mobile payment terminal.',
            keywords: 'login, account access, DC Tap to Pay console, payment management',
          };
      default:
        return {
          title: 'DC Tap to Pay - Seamless Mobile Payment Solutions',
          description: 'DC Tap to Pay offers innovative contactless payment solutions for businesses. Transform your smartphone into a secure payment terminal and streamline your transactions.',
          keywords: 'DC Tap to Pay, mobile payments, contactless payments, payment solutions, business technology',
          ogImage: dcpayIos
        };
    }
  };

  const seoData = getSeoData(location.pathname);


  useEffect(() => {
    // Set up an interceptor to handle 401 responses globally
    const interceptor = axios.interceptors.response.use(
      (response) => {
        // Check if the response indicates an expired token
        if (response.data && response.data.isValid === false) {
          // handleLogout();
          // navigate('/console');
        }
        return response;
      },
      (error) => {
        if (error.response?.status === 401) {
          // handleLogout();
          // navigate('/console');
          console.log("forbidden");
        }
        return Promise.reject(error);
      }
    );

    // Clean up the interceptor when the component unmounts
    return () => axios.interceptors.response.eject(interceptor);
  }, [navigate]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setIsLoading(true);
        const authStatus = await isAuthenticated();
        if (authStatus) {
          // console.log("Authenticated, setting isLoggedIn to true");
          const storedUsername = localStorage.getItem('username');
          setIsLoggedIn(true);
          setUsername(storedUsername);
        } else {
          // Only clear local storage and redirect if not on a public route
          // console.log("Not authenticated, setting isLoggedIn to false");
          if (!isPublicRoute) {
            // console.log("Logging out");
            // handleLogout();
          } else {
            // console.log("Not authenticated, but on a public route, setting isLoggedIn to false");
            setIsLoggedIn(false);
            setUsername('');
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Authentication check failed:', error);
        handleLogout();
      }
    };
    checkAuth();
  }, []);

  const handleLoginSuccess = () => {
    const storedUsername = localStorage.getItem('username');
    setIsLoggedIn(true);
    setUsername(storedUsername);
  };

  const handleLogout = () => {
    logout(); // Use the logout function from auth.js
    setIsLoggedIn(false);
    setUsername('');
    navigate('/console');
  };

  const isPublicRoute = ["/", "/privacy-policy", "/signup", "/console"].includes(location.pathname);

  return (
    <div className="relative min-h-screen flex flex-col">
      <Helmet>
        <title>{seoData.title}</title>
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:description" content={seoData.description} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoData.title} />
        <meta name="twitter:description" content={seoData.description} />
      </Helmet>
      {(!isLoading && isLoggedIn && !isPublicRoute) && (
        <div className="bg-gray-900 p-2 flex justify-end items-center space-x-4">
          <Link
            to="/console/dashboard"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          >
            Dashboard
          </Link>
          <Logout onLogout={handleLogout} username={username} />
        </div>
      )}
      <div className="flex-grow">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/signup" element={<SignUp />} />

          <Route path="/console" element={<Login onLoginSuccess={handleLoginSuccess} />} />

          <Route path="/console/*" element={
            <AuthenticatedRoute>
              <Routes>
                <Route path="dashboard" element={<Dashboard isLoggedIn={isLoggedIn} />} />
                <Route path="manage-subusers" element={<ManageSubusers isLoggedIn={isLoggedIn} />} />
                <Route path="*" element={<Navigate to="/console/dashboard" replace />} />
              </Routes>
            </AuthenticatedRoute>
          } />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Router basename="/">
        <AppContent />
      </Router>
    </HelmetProvider>
  );
}

export default App;