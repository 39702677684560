import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SalesforceCredentialsForm({ onCredentialsSaved }) {
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [loginUrl, setLoginUrl] = useState('');
  const [redirectUri, setRedirectUri] = useState('');
  const [instanceUrl, setInstanceUrl] = useState('');
  const navigate = useNavigate();

  const apiBaseUrl = process.env.REACT_APP_NODE_ENV === 'development'
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiBaseUrl}/users/salesforce-credentials`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ clientId, clientSecret, loginUrl, redirectUri, instanceUrl }),
      });
      if (response.ok) {
        onCredentialsSaved();
      } else if (response.status === 401) {
        // Handle unauthorized access
        alert('Your session has expired. Please log in again.');
        // Optionally, trigger a logout action or redirect to login page
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save credentials');
      }
    } catch (error) {
      console.error('Error saving credentials:', error);
      alert(`Error saving credentials: ${error.message}`);
    }
  };

  return (
    <div className="space-y-4">
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="text"
          value={clientId}
          onChange={(e) => setClientId(e.target.value)}
          placeholder="Salesforce Client ID"
          required
          className="w-full p-2 rounded bg-gray-700 text-white"
        />
        <input
          type="text"
          value={clientSecret}
          onChange={(e) => setClientSecret(e.target.value)}
          placeholder="Salesforce Client Secret"
          required
          className="w-full p-2 rounded bg-gray-700 text-white"
        />
        <input
          type="url"
          value={loginUrl}
          onChange={(e) => setLoginUrl(e.target.value)}
          placeholder="Salesforce Login URL"
          required
          className="w-full p-2 rounded bg-gray-700 text-white"
        />
        <input
          type="url"
          value={redirectUri}
          onChange={(e) => setRedirectUri(e.target.value)}
          placeholder="Redirect URI"
          required
          className="w-full p-2 rounded bg-gray-700 text-white"
        />
        <input
          type="url"
          value={instanceUrl}
          onChange={(e) => setInstanceUrl(e.target.value)}
          placeholder="Instance URL"
          required
          className="w-full p-2 rounded bg-gray-700 text-white"
        />
        <button
          type="submit"
          className="w-full p-3 rounded bg-indigo-500 text-white font-bold hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Save Credentials
        </button>
      </form>
    </div>
  );
}

export default SalesforceCredentialsForm;